<template>
  <div class="demo-spacing-0">
    <b-alert
      v-height-fade.appear
      :show="dismissCountDown"
      dismissible
      class="mb-0"
      :variant="alertColor"
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <div class="alert-body">
        {{ text }}
      </div>
      <b-progress
        :value="dismissCountDown"
        :max="time"
        :variant="alertColor"
        class="progress-bar-success"
        height="3px"
      />
    </b-alert>
  </div>
</template>

<script>
import { BAlert, BButton } from "bootstrap-vue";
import { heightFade } from "@core/directives/animations";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BAlert,
    BButton,
  },
  directives: {
    "height-fade": heightFade,
    Ripple,
  },
  props: {
    alertColor: {
      type: String,
      default: "warning",
    },
    time: {
      type: Number,
      default: 4,
    },
    text: String,
    alertShow: Boolean,
  },
  data() {
    return {
      dismissCountDown: 0,
    };
  },
  watch: {
    alertShow: function (newVal) {
      if (newVal == true) {
        this.showAlert();
      }
      this.$emit("alertShow", false);
    },
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.time;
    },
  },
};
</script>