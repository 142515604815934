import api from "@/libs/axios";
import Cookie from "js-cookie";

const authentication = {
    state: {
        user: [],
        userLocale: localStorage.getItem('psiqueasy-locale') || 'en'
    },
    getters: {
        returnUser: state => state.user,
        returnLocale: state => state.userLocale,
    },
    actions: {
        authenticateUser: ({ commit }, data) => {
            return new Promise(async (resolve, reject) => {
                await api.post('/login', data).then(response => {
                    Cookie.set("oliveiraToken", response.data.token, { expires: 7 });
                    if (['admin', 'merchant'].includes(response.data.users.level)) {
                        window.location.href = `${process.env.VUE_APP_FRONT_URL.replace(
                            "app",
                            "admin"
                        )}`;
                    } else {

                        response.data.users.roles.map(e => {
                            e.permissions.map(el => {
                                response.data.users.roles.push({
                                    action: 'read',
                                    subject: el.name,
                                })
                            })
                        })
                        response.data.users.roles.push({
                            action: 'read',
                            subject: 'Public',
                        })
                        commit("addUser", { dataUser: response.data.users });

                        resolve(response)
                    }
                }).catch(error => {

                    reject(error.response.data)
                })
            });
        },
        recoverUserData: ({ commit }) => {
            return new Promise(async (resolve, reject) => {
                await api.get('/profile').then(async response => {
                    Cookie.remove('oliveiraToken')
                    Cookie.set("oliveiraToken", response.data.token, { expires: 7 });

                    Cookie.remove('sysversion');
                    Cookie.set("sysversion", response.data.users?.system_info?.version, { expires: 7 });
                    if (['admin', 'merchant'].includes(response.data.users.level)) {
                        window.location.href = `${process.env.VUE_APP_FRONT_URL.replace(
                            "app",
                            "admin"
                        )}`;
                    } else {
                        await response.data.users.roles.map(e => {
                            e.permissions.map(el => {
                                response.data.users.roles.push({
                                    action: 'read',
                                    subject: el.name,
                                })
                            })
                        })
                        await response.data.users.roles.push({
                            action: 'read',
                            subject: 'Public',
                        })
                        commit("addUser", { dataUser: response.data.users });
                        resolve(response)
                    }
                }).catch(error => {
                    reject(error.response.data.message)
                })
            });
        },
        userLogout: ({ commit }) => {
            return new Promise(async (resolve, reject) => {
                await api.post('/logout').then(response => {
                    Cookie.remove('oliveiraToken')
                    commit("logout", []);
                    resolve(response)
                }).catch(error => {
                    reject(error.response.data.message)
                })
            });
        },
        changeUserLocale: ({ commit }, locale) => {
            commit("changeLocale", locale);
        },
        userRegister: ({ commit }, data) => {
            return new Promise(async (resolve, reject) => {

                function removeAccents(str) {

                    const with_accent = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";

                    const no_accent = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";

                    let newStr = "";

                    for (let i = 0; i < str.length; i++) {
                        let replacement = false;
                        for (let a = 0; a < with_accent.length; a++) {
                            if (str.substr(i, 1) == with_accent.substr(a, 1)) {
                                newStr += no_accent.substr(a, 1);
                                replacement = true;
                                break;
                            }
                        }
                        if (replacement == false) {
                            newStr += str.substr(i, 1);
                        }
                    }
                    return newStr;
                }

                function getRandomArbitrary(min, max) {
                    return Math.random() * (max - min) + min;
                }

                let fullName = removeAccents(data.fullName)
                fullName = fullName.replace(/[^a-zA-Zs]/g, "").toLowerCase()
                let randomNumber = getRandomArbitrary(1, 99999)
                fullName = fullName.substring(0, 13) + Math.floor(randomNumber)
                data.username = fullName

                await api.post('/register', data).then(response => {
                    Cookie.remove('oliveiraToken')
                    Cookie.set("oliveiraToken", response.data.token, { expires: 7 });
                    response.data.users.roles.map(e => {
                        e.permissions.map(el => {
                            response.data.users.roles.push({
                                action: 'read',
                                subject: el.name,
                            })
                        })
                    })
                    response.data.users.roles.push({
                        action: 'read',
                        subject: 'Public',
                    })
                    commit("addUser", { dataUser: response.data.users });
                    resolve(response)
                }).catch(error => {
                    reject(error.response.data.errors)
                })
            });
        },
        userEditProfile: ({ commit }, data) => {
            return new Promise(async (resolve, reject) => {
                if (data.avatar) {
                    let avatar = new FormData()
                    avatar.append('avatar', data.avatar)
                    await api.post('/profile/avatar', avatar)
                }
                const dataArray = data
                if (dataArray.avatar) {
                    delete dataArray.avatar
                }
                if (dataArray) {
                    await api.patch('/profile', dataArray).then(response => {
                        resolve(response)
                    }).catch(error => {
                        reject(error.response.data.errors)
                    })
                }
            });
        },
        forgotPassword: ({ commit }, data) => {
            return new Promise(async (resolve, reject) => {
                await api.post('/forgot-password', data).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error.response.data.message)
                })

            });
        },
        redefinePassword: ({ commit }, data) => {
            return new Promise(async (resolve, reject) => {
                await api.post(data.routerRedefinePsw, { password: data.password, password_confirmation: data.password_confirmation }).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error.response.data.message)
                })

            });
        },
        newPassword: ({ commit }, data) => {
            return new Promise(async (resolve, reject) => {
                await api.post(data.routerRedefinePsw, { password: data.password, password_confirmation: data.password_confirmation }).then(response => {
                    Cookie.set("oliveiraToken", response.data.token, { expires: 7 });
                    response.data.users.roles.map(e => {
                        e.permissions.map(el => {
                            response.data.users.roles.push({
                                action: 'read',
                                subject: el.name,
                            })
                        })
                    })
                    response.data.users.roles.push({
                        action: 'read',
                        subject: 'Public',
                    })
                    commit("addUser", { dataUser: response.data.users });
                    resolve(response)
                }).catch(error => {
                    reject(error.response.data.message)
                })

            });
        },
        verifySignature: ({ commit }, routerConfirmeEmailAPI) => {
            return new Promise(async (resolve, reject) => {
                await api.get(routerConfirmeEmailAPI).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error.response.data.message)
                })

            });
        },
        confirmEmail: ({ commit }) => {
            return new Promise(async (resolve, reject) => {
                await api.post('/email/verification-notification').then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error.response.data.message)
                })
            });
        },
        confirmEmailAPI: ({ commit }, routerConfirmeEmailAPI) => {
            return new Promise(async (resolve, reject) => {
                await api.get(routerConfirmeEmailAPI).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error.response.data.message)
                })
            });
        },
        confirmEmailAPIChange: ({ commit }, routerConfirmeEmailAPI) => {
            return new Promise(async (resolve, reject) => {
                await api.get(routerConfirmeEmailAPI).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error.response.data.message)
                })
            });
        },
        oauth: ({ commit }, token) => {
            return new Promise(async (resolve, reject) => {
                await api.get(`/oauth/callback/token/${token}`).then(response => {
                    Cookie.set("oliveiraToken", response.data.token, { expires: 7 });
                    if (['admin', 'merchant'].includes(response.data.users.level)) {
                        window.location.href = `${process.env.VUE_APP_FRONT_URL.replace(
                            "app",
                            "admin"
                        )}`;
                    } else {
                        response.data.users.roles.map(e => {
                            e.permissions.map(el => {
                                response.data.users.roles.push({
                                    action: 'read',
                                    subject: el.name,
                                })
                            })
                        })
                        response.data.users.roles.push({
                            action: 'read',
                            subject: 'Public',
                        })
                        commit("addUser", { dataUser: response.data.users });
                        resolve(response)
                    }
                }).catch(error => {
                    reject(error.response.data.message)
                })

            });
        }
    },
    mutations: {
        addUser: (state, user) => {
            state.user = user.dataUser
        },
        logout: (state, user) => {
            state.user = user
        },
        changeLocale: (state, locale) => {
            state.userLocale = locale
        },
    },
}

export default authentication;