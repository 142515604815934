import api from '@/libs/axios';

const customer = {

  state: {
    productsInCart: [],
    configCart: {},
    cartProductsAddress: null,
    userInfo: null,

    productsInCartLink: [],
    productsLinkAddress: {
      street: "",
      city: "",
      district: "",
      state: "",
      number: "",
      zip_code: "",
      country: "",
      complement: "",
    },
  },
  getters: {
    returnProductsInCart: state => state.productsInCart,
    returnCartProductsAddress: state => state.cartProductsAddress,
    returnConfigCart: state => state.configCart,
    returnProductsInCartLink: state => state.productsInCartLink,
    returnProductsLinkAddress: state => state.productsLinkAddress,
  },
  actions: {

    /* ///////////////////////
                              1.0
                      ////////////////////// */
    ecommerceFetchProduct: ({ commit }, idProduct) => new Promise(async (resolve, reject) => {
      await api.get(`/customer/stores/psiqueasy/ebooks/${idProduct}`).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error.response.data.message)
      })
    }),
    ecommerceFetchCollection: ({ commit }, idProduct) => new Promise(async (resolve, reject) => {
      await api.get(`/customer/stores/psiqueasy/collections/${idProduct}`).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error.response.data.message)
      })
    }),
    ecommerceFetchCombo: ({ commit }, idProduct) => new Promise(async (resolve, reject) => {
      await api.get(`/customer/stores/psiqueasy/combos/${idProduct}`).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error.response.data.message)
      })
    }),
    buyProduct: ({ commit }, data) => new Promise(async (resolve, reject) => {
      await api.post(`/customer/stores/psiqueasy/purchases`, data).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error.response.data)
      })
    }),
    makePayment: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api.get(`/customer/purchases/${data.idSale}/payment?platform_payment=${data.payment_gateway}`).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error.response.data)
        })
      });
    },
    filterOptionsAPI: ({ commit }) => {
      return new Promise(async (resolve, reject) => {
        await api.get(`/customer/stores/psiqueasy/categories`).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error.response.data.message)
        })
      });
    },

    // ------------------------------------------------
    // 2.0
    // Product Actions
    // ------------------------------------------------

    getProductsInCart: ({ commit }) => {
      return new Promise((resolve, reject) => {
        api.get('/customer/ecommerce/cart').then(response => {
          commit('addProductsInCart', response.data);

          resolve(response.data)
        }).catch(error => {
          reject(error.response.data.message)
        })
      });
    },
    addProductInCart({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .post('/customer/ecommerce/cart', params).then(response => {
            commit('changeIdItemCart', response.data);
            resolve(response.data);
          }).catch(error => {
            reject(error.response.data.message);
          });
      });
    },
    destroyProductFromCart({ commit }, itemId) {
      return new Promise((resolve, reject) => {
        api
          .delete(`/customer/ecommerce/cart/${itemId}`).then(response => {
            //commit('destroyItemInCart', itemId);
            resolve(response.data);
          }).catch(error => {
            reject(error.response.data.message);
          });
      });
    },
    changeAmountItemCart({ commit }, params) {
      return new Promise((resolve, reject) => {
        const body = {
          amount: params.amount,
        };

        api
          .patch(`/customer/ecommerce/cart/${params.itemId}`, body).then(response => {
            //commit('changeAmountItemInCart', params);
            resolve(response.data);
          }).catch(error => {
            reject(error.response.data.message);
          });
      })
    },

    getDetailLinkPayData: ({ commit }, link) => {
      return new Promise(async (resolve, reject) => {
        await api.get(`/stores/psiqueasy/payment/link/${link}`).then(response => {
          commit('addProductsInCartLink', response.data);
          resolve(response.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    getDetailCepAddress: ({ commit }, zip_Code) => {
      return new Promise(async (resolve, reject) => {

        await fetch(`https://viacep.com.br/ws/${zip_Code}/json/`).then(response => {
          resolve(response.json())
        }).catch(error => {
          reject(error.response)
        })
      })
    },

    buyProductLinkExt: ({ commit }, data) => new Promise(async (resolve, reject) => {
      await api.post(`/stores/psiqueasy/payment/link/${data.linkId}`, data).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error.response.data)
      })
    }),
  },
  mutations: {
    addProductsInCart: (state, stockList) => {
      if (state.userInfo && localStorage.getItem(`ec-cart-${state.userInfo.id}`)) {
        state.productsInCart = JSON.parse(localStorage.getItem(`ec-cart-${state.userInfo.id}`));
      } else {
        state.productsInCart = stockList.cart_items;
        if (state.userInfo?.id) {
          setTimeout(() => {
            localStorage.setItem(`ec-cart-${state.userInfo?.id}`, JSON.stringify(stockList.cart_items));
          }, 200);
        }

      }
      state.configCart = stockList.cart_config;
    },
    changeAmountItemInCart: (state, data) => {
      const indexItem = state.productsInCart.findIndex((res) => res.id == data.itemId);
      state.productsInCart[indexItem].amount = data.amount;
      if (state.userInfo?.id) {
        localStorage.setItem(`ec-cart-${state.userInfo?.id}`, JSON.stringify(state.productsInCart));
      }

    },
    destroyItemInCart: (state, itemId) => {
      const indexItem = state.productsInCart.findIndex((res) => res.id == itemId);
      state.productsInCart.splice(indexItem, 1);
      if (state.userInfo) {
        localStorage.setItem(`ec-cart-${state.userInfo.id}`, JSON.stringify(state.productsInCart));
      }
    },
    addItemInCart: (state, data) => {
      const indexItem = state.productsInCart.findIndex((res) => res.id == data.id);
      if (indexItem > -1) {
        state.productsInCart.splice(indexItem, 1);
      }
      state.productsInCart.push(data);

      if (state.userInfo) {
        localStorage.setItem(`ec-cart-${state.userInfo.id}`, JSON.stringify(state.productsInCart));
      }
    },
    addAddressCart: (state, data) => {
      state.cartProductsAddress = data;
    },
    clearItemsCart: (state) => {
      state.productsInCart = [];
      if (state.userInfo) {
        localStorage.setItem(`ec-cart-${state.userInfo.id}`, JSON.stringify(state.productsInCart));
      }
    },
    changeIdItemCart: (state, data) => {
      let indexItem = null;

      if (data.ebook_id) {
        indexItem = state.productsInCart.findIndex((res) => res.ebook_id == data.ebook_id && res.format == data.format);
      }
      if (data.collection_id) {
        indexItem = state.productsInCart.findIndex((res) => res.collection_id == data.collection_id && res.format == data.format);
      }
      if (indexItem && state.userInfo) {
        state.productsInCart[indexItem].id = data.id;
        state.productsInCart = JSON.parse(localStorage.getItem(`ec-cart-${state.userInfo.id}`));
      }

    },
    localeUserInfoCart(state, userInfo) {
      state.userInfo = userInfo;
    },
    setFirstStor(state, userInfo) {
      state.userInfo = userInfo;
    },

    addProductsInCartLink: (state, produtctsLink) => {
      state.productsInCartLink = produtctsLink;
    },
  },
}

export default customer;
