<template>
  <b-form-group>
    <b-form-input
      id="price-min"
      v-model="price"
      v-money="money"
      :state="errors > 0 || errorPrice ? false : null"
      :disabled="disabled"
    />
    <small class="text-danger" v-if="errorPrice"
      >Preço promocional maior que o preço original</small
    >
  </b-form-group>
</template>

<script>
import { VMoney } from "v-money";
import { debounce } from "debounce";

export default {
  directives: { money: VMoney },
  props: {
    value: Number,
    disabled: Boolean,
    errors: Number,
    errorPrice: Boolean,
  },
  data() {
    return {
      price: 0,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
      },
    };
  },
  watch: {
    value: function (newVal) {
      this.price = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(newVal);
    },
    price: debounce(function (newVal) {
      let priceReturn = newVal
        .toString()
        .replaceAll("R$", "")
        .replaceAll(" ", "")
        .replaceAll(".", "")
        .replaceAll(",", ".");
      this.$emit("valueReturn", parseFloat(priceReturn));
    }, 100),
  },
};
</script>