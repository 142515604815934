import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'


import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

import '@/libs/acl'
import '@/libs/sweet-alerts'

// Vue screen
import VueScreen from 'vue-screen';
Vue.use(VueScreen);


/* import VueCryptojs from 'vue-cryptojs'
Vue.use(VueCryptojs); */


/* // Vue Scroll
import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo) */

// Vue Touch Events
import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents)

// VueClipboard
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// Vue Mask
import VueMask from 'v-mask'
Vue.use(VueMask);

// Social sharing
import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing);

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faTwitter, faFacebookF, faInstagram, faWhatsapp, faLinkedinIn, faYoutube, faTelegram } from '@fortawesome/free-brands-svg-icons'

/* add icons to the library */
library.add(faTwitter, faFacebookF, faInstagram, faWhatsapp, faLinkedinIn, faYoutube, faTelegram)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

// Global Components
import './global-components/global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// Animate CSS
import 'animate.css';

// SweetAlertIcons
import SweetAlertIcons from 'vue-sweetalert-icons';

import './registerServiceWorker'
Vue.use(SweetAlertIcons);

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')


// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')

if (process.env.VUE_APP_FRONT_URL !== "http://localhost:8080") {
  if (!['https:', 'https'].includes(window.location.protocol)) {
    window.location.href = process.env.VUE_APP_FRONT_URL;
  }
}


console.log(
  "Developed by \n" +
  "%c" + "OLIVEIRACORP", "color: #7289DA; font-size: 40px; font-weight: bold;",
  "\n       https://www.oliveiracorp.com.br");
