import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';
import authentication from './authentication';



import customer from "./customer";
import ecommerce from "./ecommerce";


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    authentication,
    customer,
    ecommerce,
  },
  strict: process.env.DEV,
})