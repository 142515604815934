 
<template>
  <div class="d-flex">
    <div class="widthMax">
      <vue-autosuggest
        :suggestions="filteredOptions"
        :input-props="inputProps"
        :limit="10"
        v-model="query"
        @selected="onSelected"
        @input="onInputChange"
        v-show="
          !error[0]
            ? unique
              ? selected.length > 0
                ? false
                : true
              : true
            : false
        "
      >
        <template slot-scope="{ suggestion }">
          <div class="d-flex align-items-center">
            <b-avatar
              rounded
              :src="suggestion.item.img"
              v-show="suggestion.item.img"
            />
            <div class="detail ml-50">
              <b-card-text class="mb-0">
                {{ suggestion.item.name }}
              </b-card-text>
              <small class="text-muted">
                {{ suggestion.item.subname }}
              </small>
            </div>
          </div>
        </template>
      </vue-autosuggest>
      <div v-if="error[0]">
        <b-form-input
          id="title"
          :value="query"
          :state="false"
          @mouseenter="() => $emit('removeError')"
        />
        <small class="text-danger">{{ error[1] }}</small>
      </div>
      <div
        class="list-ebooks-selected mt-2"
        v-for="item in selected"
        :key="item.id"
      >
        <div class="d-flex">
          <b-avatar rounded :src="item.img" v-show="item.img" />
          <div class="detail ml-50">
            <b-card-text class="mb-0">
              {{ item.name }}
            </b-card-text>
            <small class="text-muted">
              {{ item.subname }}
            </small>
          </div>
        </div>
        <b-button
          variant="flat-danger"
          @click="() => $emit('removeElementFromList', item.id)"
          >Remover</b-button
        >
      </div>
    </div>
    <a
      v-if="addButton ? (unique && selected.length > 0 ? false : true) : false"
      @click="() => this.$emit('eventButton', text)"
    >
      <feather-icon size="34" icon="PlusIcon" class="text-success ml-1" />
    </a>
  </div>
</template>

<script>
import { VueAutosuggest } from "vue-autosuggest";

export default {
  components: {
    VueAutosuggest,
  },
  props: {
    addButton: {
      type: Boolean,
    },
    unique: {
      type: Boolean,
    },
    placeholder: {
      type: String,
      default: "Digite algo para ver sugestões",
    },
    error: {
      type: Array,
      default: function () {
        return [false, ""];
      },
    },
    datasuggest: {
      type: Array,
      default: function () {
        return [
          {
            id: 1,
            img: require("@/assets/images/portrait/small/avatar-s-4.jpg"),
            name: "E-book ABCDEFGHIJKLMNOPQRSTUVWXYZ",
            subname: "Jonatas Caires",
          },
        ];
      },
    },
    selected: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      filteredOptions: [],
      inputProps: {
        id: "autosuggest__input",
        class: "form-control",
        placeholder: this.placeholder,
      },
      query: "",
      text: "",
    };
  },
  watch: {
    selected: function (newVal) {
      this.$emit("value", newVal);
    },
  },
  methods: {
    onSelected(option) {
      this.query = "";
      let itemSelected = this.selected.filter((item) => item == option.item)[0];
      if (!itemSelected) this.selected.push(option.item);
    },
    onInputChange(text) {
      if (text === "" || text === undefined) {
        return;
      }

      this.text = text;

      const filteredData = this.datasuggest.filter(
        (item) => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1
      );
      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
    },
  },
};
</script>

<style scoped>
.list-ebooks-selected {
  display: flex;
  justify-content: space-between;
}

.widthMax {
  width: 100%;
}
</style>