<template>
  <div>
    <h5 class="mb-2">
      {{ $t(title) }}
    </h5>
    <vue-slider
      v-model="valueSlider"
      disabled
      :max="2000"
      :interval="1"
      :class="
        title == 'printed'
          ? 'vue-slider-success'
          : title == 'digital'
          ? 'vue-slider-info'
          : 'vue-slider-warning'
      "
    />
    <div class="d-flex justify-content-around mt-2">
      <div>
        <label style="font-size: 1rem">{{
          $t("Preço: ")
        }}</label>
        <validation-observer ref="inputPrice">
          <validation-provider
            #default="{ errors }"
            mode="eager"
            :name="$i18n.t('Preço')"
            :vid="`price_${title}`"
          >
            <input-money
              :value="rdot"
              @valueReturn="(val) => (rdot = val)"
              :errors="errors.length"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </validation-observer>
      </div>
      <div>
        <label style="font-size: 1rem">{{
          $t("Preço promocional: ")
        }}</label>
        <div class="price-promotional">
          <checkbox
            style="margin-top: -1rem"
            :selected="pricePromotional"
            @changeFormat="(val) => (pricePromotional = val)"
          />
          <input-money
            :value="ldot"
            @valueReturn="
              (val) => (pricePromotional ? (ldot = val) : (ldot = 0))
            "
            :disabled="!pricePromotional"
            :errorPrice="this.ldot > this.rdot"
          />
        </div>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
import {
  BFormSelect,
  BFormSelectOption,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import VueSlider from "vue-slider-component";
import "@core/scss/vue/libs/vue-slider.scss";
import InputMoney from "./InputMoney.vue";

export default {
  components: {
    VueSlider,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BFormSelectOption,
    InputMoney,
  },
  data() {
    return {
      ldot: 0,
      rdot: 0,
      pricePromotional: false,

      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false,
      },
    };
  },
  props: {
    title: String,
    updateValue: Array,
  },
  computed: {
    valueSlider() {
      return this.pricePromotional ? [this.ldot, this.rdot] : this.rdot
    }
    // value: {
    //   get() {
    //     if (this.pricePromotional) {
    //       return [this.ldot, this.rdot];
    //     } else {
    //       return this.rdot;
    //     }
    //   },
    //   set(val) {
    //     if (this.pricePromotional) {
    //       this.ldot = val[0];
    //       this.rdot = val[1];
    //     } else {
    //       this.rdot = val;
    //     }
    //   },
    // },
  },
  mounted() {
    if (this.updateValue) {
      this.ldot = this.updateValue[0];
      this.rdot = this.updateValue[1];
      if (this.updateValue[0] > 0) {
        this.pricePromotional = true;
      } else {
        this.pricePromotional = false;
      }
    }
  },
  watch: {
    title: function (newVal) {
      if (this.updateValue) {
        this.ldot = this.updateValue[0];
        this.rdot = this.updateValue[1];
      }
    },
    ldot: function (newVal) {
      this.$emit("priceMin", newVal);
    },
    rdot: function (newVal) {
      this.$emit("priceMax", newVal);
    },
    pricePromotional: function (newVal) {
      this.$emit("pricePromotional", newVal);
    },
  },
};
</script>

<style scoped>
.price-promotional {
  display: flex;
  align-items: center;
}
</style>