var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"widthMax"},[_c('vue-autosuggest',{directives:[{name:"show",rawName:"v-show",value:(
        !_vm.error[0]
          ? _vm.unique
            ? _vm.selected.length > 0
              ? false
              : true
            : true
          : false
      ),expression:"\n        !error[0]\n          ? unique\n            ? selected.length > 0\n              ? false\n              : true\n            : true\n          : false\n      "}],attrs:{"suggestions":_vm.filteredOptions,"input-props":_vm.inputProps,"limit":10},on:{"selected":_vm.onSelected,"input":_vm.onInputChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var suggestion = ref.suggestion;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-avatar',{directives:[{name:"show",rawName:"v-show",value:(suggestion.item.img),expression:"suggestion.item.img"}],attrs:{"rounded":"","src":suggestion.item.img}}),_c('div',{staticClass:"detail ml-50"},[_c('b-card-text',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(suggestion.item.name)+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(suggestion.item.subname)+" ")])],1)],1)]}}]),model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}),(_vm.error[0])?_c('div',[_c('b-form-input',{attrs:{"id":"title","value":_vm.query,"state":false},on:{"mouseenter":function () { return _vm.$emit('removeError'); }}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.error[1]))])],1):_vm._e(),_vm._l((_vm.selected),function(item){return _c('div',{key:item.id,staticClass:"list-ebooks-selected mt-2"},[_c('div',{staticClass:"d-flex"},[_c('b-avatar',{directives:[{name:"show",rawName:"v-show",value:(item.img),expression:"item.img"}],attrs:{"rounded":"","src":item.img}}),_c('div',{staticClass:"detail ml-50"},[_c('b-card-text',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(item.subname)+" ")])],1)],1),_c('b-button',{attrs:{"variant":"flat-danger"},on:{"click":function () { return _vm.$emit('removeElementFromList', item.id); }}},[_vm._v("Remover")])],1)})],2),(_vm.addButton ? (_vm.unique && _vm.selected.length > 0 ? false : true) : false)?_c('a',{on:{"click":function () { return this$1.$emit('eventButton', _vm.text); }}},[_c('feather-icon',{staticClass:"text-success ml-1",attrs:{"size":"34","icon":"PlusIcon"}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }