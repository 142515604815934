import api from "@/libs/axios";
import Cookie from "js-cookie";
import advancedPrint from "./advanced-print/advancedPrint"
import reversal from "./reversal/reversal"

const customer = {
    modules: {
        advancedPrint,
        reversal
    },
    state: {},
    getters: {},
    actions: {
        // rota antiga -> filtro e busca
        userEbooksList: ({ commit }, searchQuery) => {
            return new Promise(async (resolve, reject) => {
                let newParams = ''
                if (searchQuery != '') {
                    newParams = '?search=' + searchQuery
                }
                await api.get(`/customer/shopping/list${newParams}`).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data.message)
                })
            });
        },
        // rota nova -> retorna tudo
        userEbooksListAll: ({ commit }, searchQuery) => {
            return new Promise(async (resolve, reject) => {
                let newParams = ''
                if (searchQuery != '') {
                    newParams = '?search=' + searchQuery
                }
                await api.get(`/customer/shopping/list/all${newParams}`).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data.message)
                })
            });
        },
        // #
        userColletionDetailShop: ({ commit }, params) => {
            // console.log(params);
            return new Promise(async (resolve, reject) => {
                await api.get(`/customer/shopping/purchases/${params.purchaseId}/collection/${params.colletionId}`).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data.message)
                })
            });
        },
        userComboDetailShop: ({ commit }, params) => {
            return new Promise(async (resolve, reject) => {
                await api.get(`/customer/shopping/purchases/${params.purchaseId}/combos/${params.comboId}`).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data.message)
                })
            });
        },
        listStoreOffersForUser: ({ commit }, searchQuery) => {
            return new Promise(async (resolve, reject) => {
                let newParams = ''
                if (typeof searchQuery == 'object') {
                    if (searchQuery.collection_id) {
                        newParams = '?collection_id=' + searchQuery.collection_id
                    }
                    if (searchQuery.ebook_id) {
                        newParams = '?ebook_id=' + searchQuery.ebook_id
                    }
                    if (searchQuery.combo_id) {
                        newParams = '?combo_id=' + searchQuery.combo_id
                    }
                } else {
                    if (searchQuery != '') {
                        newParams = '?search=' + searchQuery
                    }
                }
                await api.get(`/customer/stores/psiqueasy/offers${newParams}`).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data.message)
                })
            });
        },
        // ofertas filtradas
        listStoreOffersEbooksAll: ({ commit }, params) => {
            console.log('filtro');
            return new Promise(async (resolve, reject) => {
                let newParams = ''
                if (params.searchQuery != '') {
                    newParams = newParams + '&search=' + params.searchQuery
                }
                if (params.categorieFilter != 0) {
                    newParams = newParams + '&categorieId=' + params.categorieFilter
                }
                if (params.sortBy) {
                    newParams = newParams + '&sortBy=' + params.sortBy
                }
                if (params.sortDirection) {
                    newParams = newParams + '&sortDirection=' + params.sortDirection
                }
                await api.get(`/customer/stores/psiqueasy/offers/filterAll?page=${params.currentPage}&limit=${params.perPage}${newParams}`).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data.message)
                })
            });
        },
        // todas as ofertas
        listStoreOffersAll: ({ commit }, params) => {
            console.log('all');
            return new Promise(async (resolve, reject) => {
                let newParams = ''
                if (params.searchQuery != '') {
                    newParams = newParams + '&search=' + params.searchQuery
                }
                if (params.categorieFilter != 0) {
                    newParams = newParams + '&categorieId=' + params.categorieFilter
                }
                if (params.sortBy) {
                    newParams = newParams + '&sortBy=' + params.sortBy
                }
                if (params.sortDirection) {
                    newParams = newParams + '&sortDirection=' + params.sortDirection
                }
                await api.get(`/customer/stores/psiqueasy/offers/all?page=${params.currentPage}&limit=${params.perPage}${newParams}`).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data.message)
                })
            });
        },
        listStorePromotionsEbooksAll: ({ commit }, params) => {
            return new Promise(async (resolve, reject) => {
                let newParams = ''
                if (params.searchQuery != '') {
                    newParams = newParams + '&search=' + params.searchQuery
                }
                if (params.categorieFilter != 0) {
                    newParams = newParams + '&categorieId=' + params.categorieFilter
                }
                if (params.sortBy) {
                    newParams = newParams + '&sortBy=' + params.sortBy
                }
                if (params.sortDirection) {
                    newParams = newParams + '&sortDirection=' + params.sortDirection
                }
                await api.get(`/customer/stores/psiqueasy/promotions/filter?page=${params.currentPage}&limit=${params.perPage}${newParams}`).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data.message)
                })
            });
        },
        listNextReleases: ({ commit }, searchQuery) => {
            return new Promise(async (resolve, reject) => {
                let newParams = ''
                if (searchQuery != '') {
                    newParams = '?search=' + searchQuery
                }
                await api.get(`/customer/stores/psiqueasy/releases${newParams}`).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data.message)
                })
            });
        },
        listUpcomingReleases: ({ commit }, params) => {
            return new Promise(async (resolve, reject) => {

                await api.get(`/customer/stores/psiqueasy/upcoming/releases/filter?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy}&sortDirection=${params.sortDirection}`).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data.message)
                })
            });
        },
        verifyLinkFirstAccess: ({ commit }, linkValidate) => {
            return new Promise(async (resolve, reject) => {
                await api.get(`/customer${linkValidate}`).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data.message)
                })
            });
        },
        termsOfUse: ({ commit }) => {
            return new Promise(async (resolve, reject) => {
                await api.get(`/terms/${process.env.VUE_APP_API_TERMS_OF_USE_ID}`).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data.message)
                })
            });
        },
        finishFirstAccess: ({ commit }, dataUser) => {
            const linkValidate = dataUser.linkValidate
            delete dataUser.linkValidate
            delete dataUser.passwordConfirm
            return new Promise(async (resolve, reject) => {
                await api.post(`/customer${linkValidate}`, dataUser).then(response => {
                    Cookie.set("oliveiraToken", response.data.token);
                    response.data.users.roles.map(e => {
                        e.permissions.map(el => {
                            response.data.users.roles.push({
                                action: 'read',
                                subject: el.name,
                            })
                        })
                    })
                    response.data.users.roles.push({
                        action: 'read',
                        subject: 'Public',
                    })
                    commit("addUser", { dataUser: response.data.users });
                    resolve(response)
                }).catch(error => {
                    reject(error.response.data.message)
                })
            });
        },
        pdfViewCustomer: ({ commit }, params) => {
            return new Promise(async (resolve, reject) => {
                if (params.type == 'ebook') {
                    await api.get(`/customer/purchases/${params.purchase_id}/ebooks/${params.ebook_id}/urlgen?page=${params.page}`).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error.response.data)
                    })
                } else if (params.type == 'preview') {
                    await api.get(`/customer/ebooks/${params.ebook_id}/urlgen/preview?page=${params.page}`).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error.response.data)
                    })
                }
            })
        },
        pdfViewCustomerPrint: ({ commit }, params) => {
            return new Promise(async (resolve, reject) => {
                let rangePage = ''
                params.rangePage.map((el, idx) => {
                    if (idx == 0) {
                        rangePage = el.value.toString()
                    } else {
                        rangePage = rangePage.concat(`,${el.value.toString()}`)
                    }
                })
                await api.get(`/customer/purchases/${params.purchase_id}/ebooks/${params.ebook_id}/urlgen/print?rangePage=${rangePage}`).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            })
        },
        getUserProfileAddress: ({ commit }) => {
            return new Promise(async (resolve, reject) => {
                await api.get(`/profile/addresses`).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data.message)
                })
            });
        },
        createUserProfileAddress: ({ commit }, data) => {
            return new Promise(async (resolve, reject) => {
                await api.post(`/profile/addresses`, data).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data.errors)
                })
            });
        },
        userChangeAddressDefault: ({ commit }, idAddress) => {
            return new Promise(async (resolve, reject) => {
                await api.patch(`/profile/addresses/${idAddress}`, {
                    default: true
                }).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data.message)
                })
            });
        },
        customerRequestsList: ({ commit }, params) => {
            return new Promise(async (resolve, reject) => {
                let outhersParams = ''
                if (params.search != '' && params.search != null) {
                    outhersParams = outhersParams + `&search=${params.search}`
                }
                if (params.format != '' && params.format != null) {
                    outhersParams = outhersParams + `&paymentStatus=${params.paymentStatus}`
                }
                if (params.category != '' && params.category != null) {
                    outhersParams = outhersParams + `&PlatformId=${params.PlatformId}`
                }

                if (params.sortby == null) {
                    params.sortby = 'created_at'
                }
                if (params.sortdirection == null) {
                    params.sortdirection = 'desc'
                }
                if (params.page == null) {
                    params.page = '1'
                }
                if (params.limit == null) {
                    params.limit = '10'
                }
                if (params.status == null) {
                    params.status = 'active'
                }
                await api.get(`/customer/purchases?page=${params.page}&limit=${params.limit}&sortBy=${params.sortby}&sortDirection=${params.sortdirection}${outhersParams}`).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data.message)
                })
            });
        },
        customerTicketsList: ({ commit }, params) => {
            return new Promise(async (resolve, reject) => {
                let outhersParams = ''

                if (params.sortby == null) {
                    params.sortby = 'created_at'
                }
                if (params.sortdirection == null) {
                    params.sortdirection = 'desc'
                }
                if (params.page == null) {
                    params.page = '1'
                }
                if (params.limit == null) {
                    params.limit = '10'
                }
                if (params.search) {
                    outhersParams += `&search=${params.search}`
                }
                await api.get(`/customer/tickets?page=${params.page}&limit=${params.limit}&sortBy=${params.sortby}&sortDirection=${params.sortdirection}${outhersParams}`).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data.message)
                })
            });
        },
        customerTicketDetails: ({ commit }, params) => {
            return new Promise(async (resolve, reject) => {

                await api.get(`/customer/tickets/${params.protocolNumber}`).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data.message)
                })
            });
        },
        customerChangeStatusTicket: ({ commit }, params) => {
            return new Promise(async (resolve, reject) => {
                await api.patch(`/customer/tickets/${params.protocolNumber}`, { status: 2 }).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data.message)
                })
            });
        },
        customerTicketComment: ({ commit }, params) => {
            return new Promise(async (resolve, reject) => {

                await api.post(`/customer/tickets/${params.protocolNumber}/comments`, {
                    comment: params.comment,
                }).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            });
        },
        customerTicketCommentStar: ({ commit }, params) => {
            return new Promise(async (resolve, reject) => {

                await api.post(`/customer/tickets/${params.protocolNumber}/comments/${params.commentId}/stars`, {
                    star: params.star,
                }).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            });
        },
        getUniqueRequest: ({ commit }, idSale) => {
            return new Promise(async (resolve, reject) => {
                await api.get(`/customer/purchases/${idSale.id}`)
                    .then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error.response.data)
                    })
            })
        },
        getRequestStoreInfo: ({ commit }) => {
            return new Promise(async (resolve, reject) => {
                await api.get(`/customer/stores/psiqueasy/info`)
                    .then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error.response.data)
                    })
            })
        },
        getClientScreen: ({ commit }, screen) => {
            return new Promise(async (resolve, reject) => {
                await api.get(`/profile/screen/scalings?scaling=${screen}`)
                    .then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error.response.data)
                    })
            })
        },
        saveClientScreen: ({ commit }, screen) => {
            return new Promise(async (resolve, reject) => {
                await api.post(`/profile/screen/scalings`, { scaling: screen })
                    .then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error.response.data)
                    })
            })
        },
        downloadEbook: ({ commit }, data) => {
            return new Promise(async (resolve, reject) => {
                await api.get(`/customer/purchases/${data.purchase_id}/ebooks/${data.ebook_id ? data.ebook_id : data.id}/down`)
                    .then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error.response.data)
                    })
            })
        },
        downloadPreviewEbook: ({ commit }, data) => {
            return new Promise(async (resolve, reject) => {
                await api.get(`/customer/ebooks/${data.id}/urlgen/preview/down`)
                    .then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error.response.data)
                    })
            })
        },
        customerService: ({ commit }) => {
            return new Promise(async (resolve, reject) => {
                await api.get(`/customer/stores/psiqueasy/contacts`)
                    .then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error.response.data)
                    })
            })
        },
        customerServiceOpen: ({ commit }) => {
            return new Promise(async (resolve, reject) => {
                await api.get(`/stores/psiqueasy/contacts`)
                    .then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error.response.data)
                    })
            })
        },
        getBudgetData: ({ commit }, link) => {
            return new Promise(async (resolve, reject) => {
                await api.get(`/customer/budget/purchases/${link}`).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            })
        },
        createTicket: async ({ commit }, params) => {

            let formData = new FormData();
            formData.append("title", params.title);
            formData.append("reason", params.reason);
            formData.append("phone_number", params.phone_number);
            formData.append("category_id", params.category_id);
            formData.append("priority_id", params.priority_id);
            if (params?.file_image?.length > 0) {
                for (const key in params.file_image) {
                    formData.append(`file_image[${key}]`, await (await fetch(params.file_image[key].path)).blob());
                }
            }
            return new Promise(async (resolve, reject) => {
                await api.post(`/customer/tickets`, formData).then(response => {
                    //commit("addPurchasesRefunds", response.data);
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            });
        },
        requestChangeItinOrFullname: async ({ commit }, params) => {

            let formData = new FormData();
            if (params.itin_number) {
                formData.append("itin_number", params.itin_number);
            }
            if (params.fullName) {
                formData.append("fullName", params.fullName);
            }
            if (params?.file_image?.length > 0) {
                for (const key in params.file_image) {
                    formData.append(`doc_file`, await (await fetch(params.file_image[0].path)).blob());
                }
            }
            return new Promise(async (resolve, reject) => {
                await api.post(`/profile/change`, formData).then(response => {
                    //commit("addPurchasesRefunds", response.data);
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            });
        },
        getCustomerCategories: ({ commit }, link) => {
            return new Promise(async (resolve, reject) => {
                await api.get(`/customer/tickets/categories`).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            })
        },
        getCustomerPriorities: ({ commit }, link) => {
            return new Promise(async (resolve, reject) => {
                await api.get(`/customer/tickets/priorities`).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            })
        },
        getNotifications: ({ commit }, link) => {
            return new Promise(async (resolve, reject) => {
                await api.get(`/customer/notification`).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            })
        },
        viewNotificationsChange: ({ commit }, params) => {
            return new Promise(async (resolve, reject) => {
                await api.patch(`/customer/notification/${params.id}`, {
                    status: "true",
                    type: params.not_type
                }).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            })
        },
    },
    mutations: {},
}

export default customer;