<template>
  <!-- Brand logo-->
  <b-link class="brand-logo d-none d-lg-flex" v-once>
    <b-img height="34" :src="psiquEasyLogo" alt="Login V2" />
    <h2 class="brand-text text-primary ml-1">
      <b-img height="44" :src="imgUrl" alt="Login V2" />
    </h2>
  </b-link>
  <!-- /Brand logo-->
</template>

<script>
import ProjectLogo from "@/layouts/logo/Logo.vue";
import { $themeConfig } from "@themeConfig";
import store from "@/store/index";
import { BLink, BImg } from "bootstrap-vue";

export default {
  components: {
    ProjectLogo,
    BLink, 
    BImg
  },
  data() {
    return {
      sideImg: require("@/assets/images/logo/logo-psiqueasy-store-light.png"),
      psiquEasyLogo: require("@/assets/images/logo/ico-psiqueasy-store.png"),
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/logo/logo-psiqueasy-store-dark.png");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  setup() {
    const { appName } = $themeConfig.app;
    return {
      appName,
    };
  },
};
</script>