import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store/index";
import Cookie from "js-cookie";
import { canNavigate } from '@/libs/acl/routeProtection'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: function (to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [

    // *===============================================---*
    // *--------- PUBLIC ---------------------------------*
    // *===============================================---*
    {
      path: '/login/:locale?',
      name: 'login',
      component: () => import(/* webpackChunkName: "login" */'@/views/authentication/Login.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Public'
      },
    },
    {
      path: '/register/:locale?',
      name: 'register',
      component: () => import(/* webpackChunkName: "register" */'@/views/authentication/Register.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Public'
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import(/* webpackChunkName: "forgot_password" */'@/views/authentication/ForgotPassword.vue'),
      meta: {
        layout: 'full',
        resource: 'Public',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/reset-password/:token/:email',
      name: 'reset-password',
      component: () => import(/* webpackChunkName: "reset_password" */'@/views/authentication/ResetPassword.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Public'
      },
    },
    {
      path: '/new-password/:token/:email',
      name: 'new-password',
      component: () => import(/* webpackChunkName: "cew_password" */'@/views/authentication/NewPassword.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Public'
      },
    },
    {
      path: '/oauth/callback/token/:token',
      name: 'callback-oauth',
      component: () => import(/* webpackChunkName: "callback_oauth" */'@/views/authentication/CallbackOAuth.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Public'
      },
    },
    {
      path: '/verify-email/:id/:token',
      name: 'verify-email',
      component: () => import(/* webpackChunkName: "verify_email" */'@/views/authentication/VerifyEmail.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Public'
      },
    },
    {
      path: '/verify-email/:id/:email/change/mail',
      name: 'verify-email-change',
      component: () => import(/* webpackChunkName: "verify_email_change" */'@/views/authentication/VerifyEmailChange.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Public'
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import(/* webpackChunkName: "error404" */'@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Public'
      },
    },
    {
      path: '/not-authorized',
      name: 'not-authorized',
      // ! Update import path
      component: () => import(/* webpackChunkName: "not_authorized" */'@/views/error/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Public'
      },
    },
    {
      path: '/terms-of-use',
      name: 'terms-of-use',
      // ! Update import path
      component: () => import(/* webpackChunkName: "terms_of_use" */'@/views/TermsOfUse.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Public',
      },
    },
    {
      path: '/first/access/:params/:params2',
      name: 'first-access',
      component: () => import(/* webpackChunkName: "first_access" */'@/views/customer/first-access/FirstAccess.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Public',
      },
    },
    // *===============================================---*
    // *--------- CUSTOMER -------------------------------*
    // *===============================================---*
    {
      path: '/',
      name: 'redirect',
      component: () => import(/* webpackChunkName: "redirect" */'@/views/Redirect.vue'),
      meta: {

      },
    },
    // rota visualizador de epub
    {
      path: '/epub/:purchase/:id/:key/:back',
      name: 'epub',
      component: () => import(/* webpackChunkName: "epub_controller" */'@/views/epub/epubController.vue'),
      meta: {
        layout: 'full',

      },
    },
    {
      path: '/home',
      name: 'client-home',
      component: () => import(/* webpackChunkName: "home_page" */'@/views/customer/home/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],

      },
    },
    {
      path: '/faq',
      name: 'faq',
      component: () => import(/* webpackChunkName: "faq_page" */'@/views/customer/faq/Faq.vue'),
      meta: {
        pageTitle: 'FAQ',
        breadcrumb: [
          {
            text: 'FAQ',
            active: true,
          },
        ],

      },
    },
    {
      path: '/view-pdf/:idSale/:idEbook/:page/:back',
      name: 'view_pdf',
      component: () => import(/* webpackChunkName: "customer_ebook_pdf_view" */'@/views/customer/home/ebook-view/CustomerEbookPdfView.vue'),
      meta: {
        layout: 'full',

      },
    },

    {
      path: '/my-products',
      name: 'my_products',
      component: () => import(/* webpackChunkName: "my_products_page" */'@/views/customer/my-products/MyProducts.vue'),
      meta: {
        pageTitle: 'My products',
        breadcrumb: [
          {
            text: 'My products',
            active: true,
          },
        ],

      },
    },
    {
      path: '/my-collection/:idPurchase/:idCollection/:nameCollection', //#
      name: 'my_collection',
      component: () => import(/* webpackChunkName: "my_collection" */'@/views/customer/my-products/MyCollection.vue'),
      meta: {
        pageTitle: 'My collection',
        breadcrumb: [
          {
            text: 'My products',
            to: '/my-products',
          },
          {
            text: 'My collection',
            active: true,
          },
        ],

      },
    },
    {
      path: '/my-combo-collection/:idCollection/:idCombo/:idPurchase/:nameCollection',
      name: 'my_combo-collection',
      component: () => import(/* webpackChunkName: "my_combo_collection" */'@/views/customer/my-products/MyComboCollection.vue'),
      meta: {
        pageTitle: 'My collection',
        breadcrumb: [
          {
            text: 'My products',
            to: '/my-products',
          },
          {
            text: 'My collection',
            active: true,
          },
        ],

      },
    },
    {
      path: '/my-combo/:idCombo/p/:idPurchase/:nameCombo',
      name: 'my_combo',
      component: () => import(/* webpackChunkName: "my_combo" */'@/views/customer/my-products/MyCombo.vue'),
      meta: {
        pageTitle: 'Voltar',
        breadcrumb: [
          {
            text: 'My products',
            to: '/my-products',
          },
          {
            text: 'My combo',
            active: true,
          },
        ],

      },
    },
    {
      path: '/print-advanced/:idPurchase/:idEbook',
      name: 'print_advanced',
      component: () => import(/* webpackChunkName: "print_advanced" */'@/views/customer/my-products/print-advanced/PrintAdvanced.vue'),
      meta: {
        pageTitle: 'My collection',
        breadcrumb: [
          {
            text: 'My products',
            to: '/my-products',
          },
          {
            text: 'Print Advanced',
            active: true,
          },
        ],

      },
    },
    {
      path: '/my-requests',
      name: 'my_requests',
      component: () => import(/* webpackChunkName: "my_requests_list" */'@/views/customer/my-requests/MyRequestsList.vue'),
      meta: {
        pageTitle: 'My requests',
        breadcrumb: [
          {
            text: 'My requests',
            active: true,
          },
        ],

      },
    },
    {
      path: '/my-request-preview/refunds/:id',
      name: 'my_requests_refund',
      component: () => import(/* webpackChunkName: "my_requests_refund" */'@/views/customer/my-requests/MyRequestRefund.vue'),
      meta: {
        pageTitle: 'My requests',
        breadcrumb: [
          {
            text: 'Refund',
            active: true,
          },
        ],

      },
    },
    {
      path: '/my-ticket-create/tiicket',
      name: 'my_ticket_create',
      component: () => import(/* webpackChunkName: "my_ticket_create" */'@/views/customer/my-tickets/MyTicketCreate.vue'),
      meta: {
        pageTitle: 'Create ticket',
        breadcrumb: [
          {
            text: 'Ticket',
            active: true,
          },
        ],

      },
    },


    {
      path: '/my-request-preview/:id',
      name: 'my_request_preview',
      component: () => import(/* webpackChunkName: "my_request_preview" */'@/views/customer/my-requests/MyRequestPreview.vue'),
      meta: {

      },
    },
    {
      path: '/store-offers',
      name: 'store_offers',
      component: () => import(/* webpackChunkName: "store_offers" */'@/views/customer/StoreOffers.vue'),
      meta: {
        pageTitle: 'Store offers',
        breadcrumb: [
          {
            text: 'Store offers',
            active: true,
          },
        ],

      },
    },
    {
      path: '/account-setting',
      name: 'account-setting',
      component: () => import(/* webpackChunkName: "account_setting" */'@/views/customer/account-setting/AccountSetting.vue'),
      meta: {
        pageTitle: 'Account Settings',
        breadcrumb: [
          {
            text: 'Account Settings',
            active: true,
          },
        ],

      },
    },

    {
      path: '/budget/:params',
      name: 'budget',
      component: () => import(/* webpackChunkName: "budget_page" */'@/views/customer/sales-preview/Budget.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Public',
      },
    },

    // *===============================================---*
    // *--------- ECOMMERCE  ---------------------------------------*
    // *===============================================---*
    {
      //sem parametro de paginação
      path: '/e-commerce/shop',
      name: 'e-commerce-shop',
      component: () => import(/* webpackChunkName: "e_commerce_shop_page" */'@/views/e-commerce/e-commerce-shop/ECommerceShop.vue'),
      props: route => ({ page: parseInt(route.query.page) || 1 }),
      meta: {
        contentRenderer: 'sidebar-left-detached',
        contentClass: 'ecommerce-application',
        pageTitle: 'Shop',
        breadcrumb: [
          {
            text: 'Ofertas da loja',
            active: true,
          },
        ],

      },
    }, //###########
    {
      path: '/my-tickets',
      name: 'my_tickets',
      component: () => import(/* webpackChunkName: "my_tickets" */'@/views/customer/my-tickets/MyTicketsList.vue'),
      meta: {
        pageTitle: 'My tickets',
        breadcrumb: [
          {
            text: 'My tickets',
            active: true,
          },
        ],

      },
    },
    {
      path: '/my-tickets-preview/:protocolNumber',
      name: 'my_tickets_preview',
      component: () => import(/* webpackChunkName: "my_tickets_preview" */'@/views/customer/my-tickets/MyTicketsPreview.vue'),
      meta: {
        pageTitle: 'My tickets',
        breadcrumb: [
          {
            text: 'My tickets',
            active: true,
          },
        ],
      },
    },
    {
      path: '/e-commerce/promotions',
      name: 'e-commerce-promotions',
      component: () => import(/* webpackChunkName: "e_commerce_promotions" */'@/views/e-commerce/e-commerce-promotions/ECommercePromotions.vue'),
      meta: {
        contentRenderer: 'sidebar-left-detached',
        contentClass: 'ecommerce-application',
        pageTitle: 'Promotions',
        breadcrumb: [
          {
            text: 'Promoções da loja',
            active: true,
          },
        ],

      },
    },
    {
      path: '/e-commerce/shop/upcoming',
      name: 'e-commerce-shop-upcoming',
      component: () => import(/* webpackChunkName: "e_commerce_shop_upcoming" */'@/views/e-commerce/e-commerce-shop-upcoming/ECommerceShopUpcoming.vue'),
      meta: {

        contentClass: 'ecommerce-application',
        pageTitle: 'Upcoming Releases',
        breadcrumb: [
          {
            text: 'Próximos lançamentos',
            active: true,
          },
        ],

      },
    },
    {//#
      path: '/e-commerce/:type/:id',
      name: 'e-commerce-product-details',
      component: () => import(/* webpackChunkName: "e_commerce_product_details" */'@/views/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue'),
      meta: {
        pageTitle: 'Product Details',
        contentClass: 'ecommerce-application',
        breadcrumb: [
          {
            text: 'Shop',
            to: '/e-commerce/shop',
          },
          {
            text: 'Product Details',
            active: true,
          },
        ],

      },
    },

    {
      path: '/epub-preview/:id/:key/:back',
      name: 'epub-preview',
      component: () => import(/* webpackChunkName: "epub_preview_page" */'@/views/epub/epubControllerPreview.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'customer'
      },
    },

    {
      path: '/payss/checkout/:linkId',
      name: 'payment-linkss',
      component: () => import(/* webpackChunkName: "payment_linkss" */'@/views/customer/payment-link/PaymentLink.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Public',
      },
    },

    // *===============================================---*
    // *--------- ECOMMERCE 2.0 ---------------------------------------*
    // *===============================================---*
    {
      path: '/apps/e-commerce/checkout',
      name: 'apps-e-commerce-checkout',
      component: () => import(/* webpackChunkName: "apps_e_commerce_checkout" */'@/views/e-commerce/e-commerce-checkout/ECommerceCheckout.vue'),
      meta: {
        pageTitle: 'Checkout',
        contentClass: 'ecommerce-application',
        breadcrumb: [
          {
            text: 'ECommerce',
          },
          {
            text: 'Checkout',
            active: true,
          },
        ],
      },
    },


    // *===============================================---*
    // *--------- ERROR ---- -----------------------------*
    // *===============================================---*
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// Router Before Each hook for route protection
router.beforeEach(async (to, _, next) => {

  const appLoadingStart = document.getElementById('loading-bg')
  appLoadingStart.style.display = 'block'

  let isLoggedIn = Cookie.get('oliveiraToken');

  const userData = await store.state.authentication.user

  if (isLoggedIn && userData.length == 0) {
    await store.dispatch("recoverUserData").then(async (res) => {

      await store.dispatch("getProductsInCart").then((res) => {
      }).catch(err => { })

    })
      .catch(err => {
        Cookie.remove('oliveiraToken')
        isLoggedIn = false
      })

  }


  if (!canNavigate(to, store.state.authentication.user)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'login' })
  }



  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(async (to, _, next) => {
  // Remove start loading
  // const appLoadingEnd = document.getElementById('loading-bg')
  // if (appLoadingEnd) {
  //   appLoadingEnd.style.display = 'none'
  // }
})

export default router
