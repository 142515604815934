import api from "@/libs/axios";

const stock = {
    state: {
        ebookPurchasePrintDetail: {},
    },
    getters: {
        returnEbookPurchasePrintDetail: state => state.ebookPurchasePrintDetail,
    },
    actions: {
        ebookPurchasePrintDetail: ({ commit }, params) => {
            return new Promise(async (resolve, reject) => {
                await api.get(`/customer/purchases/${params.purchase_id}/ebooks/${params.ebook_id}`).then(response => {
                    response.data.codesHandbooks = []
                    commit("addEbookPurchasePrintDetail", response.data);
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            });
        },
        pdfRangeCustomerPrint: ({ commit }, params) => {
            let queryPa = "";
            if (params.rangeId) {
                queryPa += `&rangeId=${params.rangeId}`
            }
            return new Promise(async (resolve, reject) => {
                await api.get(`/customer/purchases/${params.purchase_id}/ebooks/${params.ebook_id}/urlgen/print?rangePage=${params.rangePrint}` + queryPa).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            })
        },
        changeStatusRangeCustomerPrint: ({ commit }, params) => {
            return new Promise(async (resolve, reject) => {
                let data = {
                    status: params.status
                }
                await api.patch(`/customer/purchases/${params.purchase_id}/ebooks/${params.ebook_id}/ranges/${params.id}/print`, data).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            })
        },
        //customer/purchases/363/ebooks/10/ranges/2/print patch
    },
    mutations: {

        addEbookPurchasePrintDetail: (state, detail) => {
            state.ebookPurchasePrintDetail = detail;
        },
        changeStatusEbookPurchasePrintDetail: (state, infoRange) => {
            let indexHand = state.ebookPurchasePrintDetail.purchaseRangePrintEbook.findIndex(object => {
                return object.id === infoRange.id;
            });
            state.ebookPurchasePrintDetail.purchaseRangePrintEbook[indexHand].status = infoRange.status;
            state.ebookPurchasePrintDetail.purchaseRangePrintEbook[indexHand].variant = infoRange.variant;

        }
    },
}

export default stock;