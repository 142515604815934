<template>
  <!-- media -->
  <b-media no-body>
    <b-media-aside>
      <!-- avatar -->
      <b-avatar
        size="80"
        rounded
        :src="urlImage ? urlImage : avatar"
        :text="avatarText(textImg ? textImg : '')"
      />
      <!--/ avatar -->
    </b-media-aside>

    <b-media-body class="mt-75 ml-75">
      <!-- upload button -->
      <b-button
        variant="primary"
        size="sm"
        class="mb-75 mr-75"
        @click="$refs.refInputEl.$el.click()"
      >
        {{ $t("Carregar capa") }}
      </b-button>
      <b-form-file
        ref="refInputEl"
        accept=".jpeg, .jpg, .png, .gif"
        :hidden="true"
        plain
        @change="onFileChange"
      />
      <!--/ upload button -->
      <div class="text-muted">
        <b-card-text :class="profileFile && 'text-warning'">
          {{
            profileFile
              ? $t("Imagem selecionada: ") + profileFile.name
              : $t("JPG, GIF ou PNG permitidos. Tamanho máximo de 4MB")
          }}
        </b-card-text>
      </div>
    </b-media-body>
  </b-media>
  <!--/ media -->
</template>

<script>
import { avatarText } from "@core/utils/filter";

export default {
  props: {
    avatar: String,
    textImg: String
  },
  data() {
    return {
      urlImage: null,
      profileFile: null,
    };
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];
      this.urlImage = URL.createObjectURL(file);
      this.profileFile = file;
      this.$emit("newAvatar", file);
    },
  },
  setup() {
    avatarText;

    return {
      avatarText,
    };
  },
};
</script>