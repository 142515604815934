import api from "@/libs/axios";

const reversalCustomer = {
    state: {
        purchasesRefunds: {},
    },
    getters: {
        returnPurchasesRefundCustomer: state => state.purchasesRefunds,
    },
    actions: {
        //customer/stores/psiqueasy/purchases/365/refunds
        purchasesRefundCustomer: ({ commit }, params) => {

            let formData = new FormData();
            formData.append("doc_file_itin", params.doc_file_itin);
            formData.append("itin_number", params.itin_number);
            formData.append("customer_reason", params.customer_reason);

            return new Promise(async (resolve, reject) => {
                await api.post(`/customer/stores/psiqueasy/purchases/${params.id}/refunds`, formData).then(response => {
                    //commit("addPurchasesRefunds", response.data);
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            });
        },
        purchasesRefundImage: ({ commit }, date) => {

            return new Promise(async (resolve, reject) => {
                await api.get(`/customer/stores/psiqueasy/refunds/${date.refund.id}/image/${date.refund.doc_file_itin}`)
                    .then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error.response.data)
                    })
            })
        },

    },
    mutations: {

        addPurchasesRefundCustomer: (state, stockList) => {
            state.purchasesRefunds = stockList
        },

    },
}

export default reversalCustomer;