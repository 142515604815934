<template>
  <b-form-checkbox
    :value="value"
    :checked="selected"
    @change="(val) => $emit('changeFormat', val)"
    :disabled="disabled"
    class="custom-control-primary"
  >
    {{ label }}
  </b-form-checkbox>
</template>

<script>
export default {
  props: {
    label: String,
    value: String,
    selected: [Array, Boolean],
    disabled: Boolean,
  },
};
</script>