import axios from 'axios'
import Cookies from 'js-cookie';

let api = process.env.VUE_APP_API_URL

const axiosIns = axios.create({
  baseURL: api,
  withCredentials: true
})

axiosIns.interceptors.request.use((config) => {
  const token = Cookies.get('oliveiraToken')
  let locale = localStorage.getItem('psiqueasy-locale')

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  if (locale) {
    if (locale == 'pt')
      locale = 'pt-br'
    config.headers.locale = locale
  }

  if ((
    config.method == 'post' ||
    config.method == 'put' ||
    config.method == 'delete'
  ) &&
    !Cookies.get('XSRF-TOKEN')) {
    return setCSRFToken()
      .then(response => config);
  }

  return config
}, (err) => {
  return Promise.reject(err)
})

const setCSRFToken = () => {
  return axiosIns.get('/csrf-cookie'); // resolves to '/api/csrf-cookie'.
}

axiosIns.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response.status === 401) {
    window.location = '/'
  }

  return Promise.reject(error)
})

export default axiosIns

