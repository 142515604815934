<template>
    <ul role="menubar" aria-disabled="false" aria-label="Pagination"
        :class="['pagination', 'b-pagination', 'justify-content-center', { 'dark-mode': darkMode }]">
        <li role="presentation" :class="{ 'page-item': true, 'disabled': currentPage === 1 }" class="prev-item">
            <button v-if="currentPage > 1" @click="previousPage" role="menuitem" aria-label="Go to previous page"
                aria-disabled="false" tabindex="0" class="page-link rounded-full focus:outline-none">
                <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-chevron-left">
                    <polyline points="15 18 9 12 15 6"></polyline>
                </svg>
            </button>
        </li>
        <li v-if="showStartEllipsis" role="presentation" class="page-item">
            <button @click="goToPage(1)" role="menuitemradio" type="button" aria-label="Go to page 1"
                :aria-checked="1 === currentPage" tabindex="0"
                class="page-link rounded-full focus:outline-none">1</button>
        </li>
        <li v-if="showStartEllipsis" role="presentation" class="page-item disabled"><span
                class="page-link rounded-full">...</span></li>
        <li v-for="pageNumber in displayedPages" :key="pageNumber" role="presentation"
            :class="{ 'page-item': true, 'active': pageNumber === currentPage, '': pageNumber === currentPage }">
            <button @click="goToPage(pageNumber)" role="menuitemradio" type="button"
                :aria-label="`Go to page ${pageNumber}`" :aria-checked="pageNumber === currentPage"
                :aria-posinset="pageNumber" :aria-setsize="totalPages" tabindex="0"
                class="page-link rounded-full focus:outline-none">{{ pageNumber }}</button>
        </li>
        <li v-if="showEndEllipsis" role="presentation" class="page-item disabled"><span
                class="page-link rounded-full">...</span></li>
        <li v-if="showEndEllipsis" role="presentation" class="page-item">
            <button @click="goToPage(totalPages)" role="menuitemradio" type="button"
                :aria-label="`Go to page ${totalPages}`" :aria-checked="totalPages === currentPage"
                :aria-posinset="totalPages" :aria-setsize="totalPages" tabindex="0"
                class="page-link rounded-full focus:outline-none">{{ totalPages }}</button>
        </li>
        <li role="presentation" :class="{ 'page-item': true, 'disabled': currentPage === totalPages }"
            class="next-item">
            <button v-if="currentPage < totalPages" @click="nextPage" role="menuitem" aria-label="Go to next page"
                aria-disabled="false" tabindex="0" class="page-link rounded-full focus:outline-none">
                <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-chevron-right">
                    <polyline points="9 18 15 12 9 6"></polyline>
                </svg>
            </button>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        totalItems: {
            type: Number,
            required: true
        },
        itemsPerPage: {
            type: Number,
            default: 10
        },
        currentPage: {
            type: Number,
            required: true
        },
        maxDisplayedPages: {
            type: Number,
            default: 5 // Quantidade máxima de botões numerados a serem exibidos
        },
        darkMode: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        totalPages() {
            return Math.ceil(this.totalItems / this.itemsPerPage);
        },
        displayedPages() {
            const pages = [];
            let start = 1;
            let end = this.totalPages;
            if (this.totalPages > this.maxDisplayedPages) {
                const mid = Math.ceil(this.maxDisplayedPages / 2);
                start = Math.max(1, this.currentPage - mid);
                end = Math.min(this.totalPages, this.currentPage + mid);
                if (this.currentPage <= mid) {
                    end = this.maxDisplayedPages;
                } else if (this.currentPage >= this.totalPages - mid) {
                    start = this.totalPages - this.maxDisplayedPages + 1;
                }
            }
            for (let i = start; i <= end; i++) {
                pages.push(i);
            }
            return pages;
        },
        showStartEllipsis() {
            return this.totalPages > this.maxDisplayedPages && this.currentPage > Math.ceil(this.maxDisplayedPages / 2) + 1;
        },
        showEndEllipsis() {
            return this.totalPages > this.maxDisplayedPages && this.currentPage < this.totalPages - Math.ceil(this.maxDisplayedPages / 2);
        }
    },
    methods: {
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.$emit('pageChanged', this.currentPage + 1);
            }
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.$emit('pageChanged', this.currentPage - 1);
            }
        },
        goToPage(pageNumber) {
            this.$emit('pageChanged', pageNumber);
        }
    }
};
</script>

<style scoped>
.page-item .active .disabled {
    color: white !important;
}
</style>
