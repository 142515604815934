import Vue from 'vue'

/*### Hand made components ###*/
import PriceSlider from "./PriceSlider"
/*
*/
Vue.component('PriceSlider', PriceSlider)

import Autosuggest from "./Autosuggest"

/*
*/
Vue.component('mPagination', mPagination)

import mPagination from "./mPagination"
/*
*/
Vue.component('Autosuggest', Autosuggest)

import Checkbox from "./Checkbox.vue"
/*
*/
Vue.component('Checkbox', Checkbox)

import AvatarImg from "./AvatarImg.vue"
/*
*/
Vue.component('AvatarImg', AvatarImg)

import CompanyLogo from "./CompanyLogo.vue"
/*
*/
Vue.component('CompanyLogo', CompanyLogo)

import CompanyLogoBudget from "./CompanyLogoBudget.vue"
/*
*/
Vue.component('CompanyLogoBudget', CompanyLogoBudget)

import CompanyLogoMobile from "./CompanyLogoMobile.vue"
/*
*/
Vue.component('CompanyLogoMobile', CompanyLogoMobile)

/*### Component FeatherIcon ###*/
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
Vue.component(FeatherIcon.name, FeatherIcon)

/*### Components Vee Validate ###*/
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('localize', localize)

/*### Alert Actions ###*/
import AlertActions from "./AlertActions"
Vue.component('AlertActions', AlertActions)

/*### Components Bootstrap Vue ###*/
import {
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormTextarea,
  BImg,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BModal,
  BPagination,
  BRow,
  BTable,
  BSpinner,
  BCardTitle,
  BForm,
  BInputGroup,
  BInputGroupAppend,
  BAlert,
  BFormSpinbutton,
  BNavbarNav,
  BFormSelect,
  BCardBody,
  BInputGroupPrepend,
  BProgress,
  BProgressBar,
  BTableLite,
  BNav,
  BNavItem,
  BOverlay,
  BFormDatepicker
} from "bootstrap-vue";
Vue.component('BAvatar', BAvatar)
Vue.component('mPagination', mPagination)
Vue.component('BButton', BButton)
Vue.component('BCard', BCard)
Vue.component('BCardText', BCardText)
Vue.component('BCol', BCol)
Vue.component('BDropdown', BDropdown)
Vue.component('BDropdownItem', BDropdownItem)
Vue.component('BFormCheckbox', BFormCheckbox)
Vue.component('BFormFile', BFormFile)
Vue.component('BFormGroup', BFormGroup)
Vue.component('BFormInput', BFormInput)
Vue.component('BFormRadio', BFormRadio)
Vue.component('BFormTextarea', BFormTextarea)
Vue.component('BImg', BImg)
Vue.component('BLink', BLink)
Vue.component('BMedia', BMedia)
Vue.component('BMediaAside', BMediaAside)
Vue.component('BMediaBody', BMediaBody)
Vue.component('BModal', BModal)
Vue.component('BPagination', BPagination)
Vue.component('BRow', BRow)
Vue.component('BTable', BTable)
Vue.component('BSpinner', BSpinner)
Vue.component('BCardTitle', BCardTitle)
Vue.component('BForm', BForm)
Vue.component('BInputGroup', BInputGroup)
Vue.component('BInputGroupAppend', BInputGroupAppend)
Vue.component('BAlert', BAlert)
Vue.component('BFormSpinbutton', BFormSpinbutton)
Vue.component('BNavbarNav', BNavbarNav)
Vue.component('BFormSelect', BFormSelect)
Vue.component('BCardBody', BCardBody)
Vue.component('BInputGroupPrepend', BInputGroupPrepend)
Vue.component('BProgress', BProgress)
Vue.component('BProgressBar', BProgressBar)
Vue.component('BTableLite', BTableLite)
Vue.component('BNav', BNav)
Vue.component('BNavItem', BNavItem)
Vue.component('BOverlay', BOverlay)
Vue.component('BFormDatepicker', BFormDatepicker)